<template>
    <div class="blogger-resources-list" v-if="translates && translates[langUrl]">
        <div class="blogger-resources-list__title">{{ translates[langUrl].pageTitle[lang] }}</div>
        <div class="blogger-resources-list__container">
            <template v-for="(item, index) in bloggerResources">
                <div 
                    v-if="item.file && item.title"
                    :key="`blogger-resource-${index}`"
                    class="blogger-resources-list__item">
                    <a :href="imageSrc(item.file)" target="_blank">
                        <v-icon>mdi-file</v-icon> <span>{{item.title}}</span>
                    </a>
                    <div class="blogger-resources-list__item__comment" v-if="item.comment">
                        {{ item.comment }}
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers';
    import moment from '@/plugins/moment';

    export default {
        name: 'BloggerResourcesList',
        metaInfo() {
            return {
                title: this.translates[this.langUrl] ? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {},
        data: () => ({
            imageSrc,
            langUrl: '/blogger/resources'
        }),
        computed: {
            ...mapState('bloggerResources', {
                bloggerResources: state => state.entities
            }),
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            await this.getBloggerResources();
            this.$root.$emit('preloaderHide');
        },
        methods: {
            async getBloggerResources() {
                await store.dispatch('bloggerResources/fetch', {});
            },
        }
    }

</script>

<style lang="scss">
    .blogger-resources-list {
        &__title {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #3A4256;
            margin: 32px 24px 12px;
            @media all and (min-width:768px) {
            font-weight: bold;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin: 40px 0 36px;
            }
        }

        &__item {
            width: 100%;
            padding: 20px;
            background: #FFFFFF;
            border-radius: 20px;
            margin-bottom: 20px;
            overflow: hidden;
            box-shadow: 0px 4px 3px rgb(44 47 56 / 2%), 0px 4px 16px rgb(44 47 56 / 12%);

            
            a {
                display: inline-flex;
                line-height: 24px;
                text-decoration: none;
                margin-bottom: 10px;
               
                .v-icon {
                    color: #da0a63;
                    margin-right: 5px;
                }
            }
        }
    }
</style>